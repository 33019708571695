
<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center" class="mx-0">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title>
              <span class="mb_max_width">Notes</span>
              <v-spacer class="mb_hidden"></v-spacer>
              <div class="table_search_field mb_max_width">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="pt-0 mt-0 assign_select assign_select_v font_400 rounded-lg"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text class="text-center">
              <v-data-table
                :server-items-length="note_count"
                data-cy="table_notes"
                :headers="headers_general"
                :items="note_objects"
                :items-per-page="100"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                :options.sync="options"
                no-data-text="No notes"
              >
                <template v-slot:top="{ pagination, options, updateOptions }"
                  ><div class="oc_table_header">
                    <v-tabs class="table_tabs pt-1" v-model="selected_table">
                      <v-tab data-cy="notes_tab_general">General</v-tab>
                      <!-- <v-tab>Notes</v-tab> -->
                    </v-tabs>
                    <v-data-footer
                      :pagination="pagination"
                      :options="options"
                      class="table_pagination"
                      @update:options="updateOptions"
                      :items-per-page-options="[50, 100, 200]"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    />
                  </div>
                </template>

                <template v-slot:[`body.prepend`]>
                  <tr class="mb_hidden" v-if="selected_table == 0">
                    <td class="align_left pl-2">
                      <v-menu
                        :close-on-content-click="false"
                        open-on-click
                        left
                        offset-overflow
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details
                            dense
                            class="assign_select assign_select_v"
                            height="32"
                            full-width
                            outlined
                            rounded
                            readonly
                            clearable
                            v-model="selected_date"
                            single-line
                            label="Date"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <template v-slot:append>
                              <v-icon
                                style="margin-top: -2px"
                                data-cy="btn_treatment_date"
                                >mdi-calendar</v-icon
                              ></template
                            >
                          </v-text-field>
                        </template>

                        <v-card>
                          <v-row>
                            <v-col>
                              <v-date-picker
                                v-model="selected_date"
                                flat
                                no-title
                                event-color="#ff9900"
                              ></v-date-picker>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-menu>
                    </td>

                    <td></td>
                    <td></td>
                  </tr>
                </template>

                <template v-slot:[`item.datetime`]="{ item }">
                  <span v-if="item.datetime">{{
                    item.datetime.split("T")[0]
                  }}</span>
                </template>

                <template v-slot:[`item.delete`]="{  }">
                  <!-- <v-icon
                    style="z-index: 10"
                    @click="
                      delete_note_id = item.id;
                      delete_dialog = true;
                    "
                    :disabled="
                      allowed_operations != 'WRITE'
                    "
                  >
                    mdi-delete-outline
                  </v-icon> -->
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="delete_dialog" max-width="400px" min-width="400px">
      <v-card class="pb-3">
        <v-card-text class="pb-0">
          <v-row>
            <v-toolbar elevation="0"
              ><h2 style="color: #0090a4">Delete Note Entry?</h2>

              <v-spacer></v-spacer>
              <v-btn icon @click="delete_dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn></v-toolbar
            ></v-row
          >
        </v-card-text>

        <v-card-actions class="pr-6">
          <v-spacer></v-spacer>
          <v-btn @click="delete_dialog = false"> Cancel </v-btn>
          <v-btn color="oc_red white--text" @click="delete_note">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <events></events> -->
  </div>
</template>

<script>
import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

export default {
  components: {
    navbar,
    sidenav,
    //events,
  },
  props: ["filter"],
  data() {
    return {
      search: "",
      selected_table: 0,
      selected_date: null,

      delete_dialog: false,
      delete_note_id: null,

      options: {
        sortBy: ["datetime"],
        sortDesc: [true],
        limit: 100,
        offset: 0,
      },

      note_objects: [],
    };
  },
  methods: {
    async delete_note() {
      try {
        this.delete_dialog = false;
        this.$store.dispatch("DDB_GET_NOTES");
      } catch (err) {
        return err;
      }
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0) + string.slice(1).toLowerCase();
      }
      return "";
    },
  },

  computed: {
    note_count() {
      return this.$store.getters.getNoteCount;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      if (this.customer_id) {
        return {
          sort: this.options.sortBy ? this.options.sortBy[0] : "datetime",
          sort_direction: this.sort_direction,
          limit: this.options.itemsPerPage,
          offset: (this.options.page - 1) * this.options.itemsPerPage,
          search: this.search,
          date: this.selected_date,
        };
      }
      return undefined;
    },
    headers_general() {
      return [
        {
          text: "Date",
          value: "datetime",
          // cellClass: this.editable ? "non-hl" : "no_edit",
          width: "190px",
          sort: () => {
            return false;
          },
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
          // cellClass: this.editable ? "non-hl" : "no_edit",
        },
        {
          text: "",
          value: "delete",
          width: "40px",
          sortable: false,
        },
      ];
    },

    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },

    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },
  watch: {
    delete_dialog() {
      if (!this.delete_dialog) {
        this.delete_note_id = null;
      }
    },
    query_params() {
      if (this.query_params) {
        this.$store.dispatch("DDB_GET_NOTES", this.query_params).then(() => {
          this.note_objects = this.$store.getters.getNotes;
        });
      }
    },
  },
  created() {},
};
</script>


<style lang="css">
.cam_icon > svg {
  height: 30px !important;
  width: 30px !important;
  margin-top: 4px;
  margin-bottom: 4px;
}
.center {
}
.select_width > .v-input {
  max-width: 160px !important;
}

.align_left {
  text-align: left;
}
.float_left {
  float: left;
}
.float_right {
  float: right;
}
.filter_btn {
  color: gray !important;
}
.v-select__selections {
  flex-wrap: nowrap !important;
}
</style>
